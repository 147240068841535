<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('ENTRIES') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            :to="{ name: 'user-configuration'}"
          >
            <span> {{ $t('ADD_USER') }} </span>
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
          <b-dropdown
            :text="$t('UPLOAD')"
            variant="gradient-primary"
            class="ml-1"
          >
            <b-dropdown-item @click="$router.push({ name: 'user-upload'})">
              <feather-icon icon="UploadIcon" />
              <span class="align-middle ml-50">{{ $t('UPLOAD_USER') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="$router.push({ name: 'user-game-upload'})">
              <feather-icon icon="UploadCloudIcon" />
              <span class="align-middle ml-50">{{ $t('UPLOAD_USER_GAME') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="$router.push({ name: 'upload-list'})">
              <feather-icon icon="ListIcon" />
              <span class="align-middle ml-50">{{ $t('UPLOAD_LIST') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          :md="userData.userRoleId===userRole.ADMIN ? 3 : 6"
        >
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            :placeholder="$t('SEARCH')"
          />
        </b-col>
        <b-col
          v-show="userData.userRoleId===userRole.ADMIN"
          cols="12"
          md="3"
        >
          <v-select
            id="id-company"
            v-model="companyFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('SELECT_COMPANY')"
            label="name"
            :options="allCompanies"
          />
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refUserListTable"
      :items="bindUsers"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <!-- Column: Name -->
      <template #cell(Name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :text="avatarText(data.item.firstName +' '+ data.item.lastName)"
              :variant="`light-${mapUserRoleToVariant(data.item.userRoleId).variant}`"
              :to="{ name: 'user-profile-id', params: { id: data.item.id } }"
            />
          </template>
          <b-link
            :to="{ name: 'user-profile-id', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.firstName }}  {{ data.item.lastName }}
          </b-link>
          <small class="text-muted">{{ data.item.email }}</small>
        </b-media>
      </template>

      <!-- Column: Role -->
      <template #cell(role)="data">
        <div class="text-nowrap">
          <feather-icon
            :icon="mapUserRoleToVariant(data.item.userRoleId).Icon"
            size="18"
            class="mr-50"
            :class="`text-${mapUserRoleToVariant(data.item.userRoleId).variant}`"
          />
          <span class="align-text-top text-capitalize">{{ mapUserRoleToVariant(data.item.userRoleId).name }}</span>
        </div>
      </template>

      <!-- Column: Last Login -->
      <template #cell(LastLogin)="data">
        <div class="d-flex align-items-left text-nowrap">
          <small class="text-muted">{{ data.item.lastLogin ? formatDateTime(data.item.lastLogin) : $t('NEVER_LOGGED') }}</small>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-send-icon`"
            icon="EditIcon"
            class="cursor-pointer"
            size="16"
            @click="$router.push({ name: 'user-configuration-id', params: { id: data.item.id }})"
          />

          <feather-icon
            :id="`invoice-row-${data.item.id}-send-icon`"
            icon="MailIcon"
            class="cursor-pointer mx-1"
            size="16"
            @click="openUserEmailModel(data.item)"
          />

          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="cursor-pointer"
            @click="$router.push({ name: 'user-profile-id', params: { id: data.item.id }})"
          />

          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="UnlockIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="$router.push({ name: 'user-password-id', params: { id: data.item.id }})"
          />

        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalUsers"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <user-email-model
      :view-user="viewUser"
    />
  </b-card>
</template>
<script>

import {
  BCard, BRow, BCol, BFormInput, BButton,
  BTable, BMedia, BAvatar, BLink,
  BPagination, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

// eslint-disable-next-line import/no-extraneous-dependencies, import/no-cycle
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import constants from '@/constants/static.json'
// eslint-disable-next-line import/named, no-unused-vars
import {
  mapUserRoleToVariant,
  mapPackageToVariant,
  paginateArray,
} from '@/constants/utils'
import useJwt from '@/auth/jwt/useJwt'
import { mixinDate } from '@/constants/mixinDate'
import { ref, computed } from '@vue/composition-api'
import mixinAlert from '@/constants/mixinAlert'
import { mapActions } from 'vuex'
import UserEmailModel from './UserEmailModel.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BPagination,
    vSelect,
    BAvatar,
    BLink,
    UserEmailModel,
    BDropdown,
    BDropdownItem,
  },
  mixins: [mixinDate],
  data() {
    return {
      userData: useJwt.getUser(),
      userRole: constants.USER_ROLE,
      viewUser: {
        id: '',
        firstName: '',
        lastName: '',
        nick: '',
        companyId: '',
        email: '',
        phonenumber: '',
        userRoleId: 0,
        aboutMe: '',
        isSendDailyEmail: false,
        language: '',
        contactEmail: '',
      },
      searchQuery: '',
      companyFilter: '',
      allUsers: [],
      bindUsers: [],
      allCompanies: [],
    }
  },
  watch: {
    currentPage: 'fetchUsers',
    perPage: 'fetchUsers',
    searchQuery: 'fetchUsers',
    companyFilter: 'fetchUsers',
  },
  created() {
    this.fetchUsers()
    this.fetchCompanies()
  },
  methods: {
    ...mapActions('company', ['fetchAllCompanies']),
    ...mapActions('user', ['fetchAllUsers']),
    openUserEmailModel(data) {
      if (data) {
        this.viewUser = { ...data }
      }
      this.$bvModal.show('user-email-modal')
    },
    fetchCompanies() {
      try {
        this.fetchAllCompanies().then(response => {
          this.allCompanies = response.data
        })
      } catch (error) {
        this.showErrorMessage()
      }
    },
    fetchUsers() {
      if (this.allUsers.length === 0) {
        try {
          this.fetchAllUsers().then(response => {
            this.allUsers = response.data
            this.totalUsers = this.allUsers.length
            this.bindUsers = paginateArray(this.allUsers, this.perPage, 1)
          })
        } catch (error) {
          this.showErrorMessage()
        }
      } else if (this.searchQuery !== '' || this.companyFilter !== undefined) {
        const filterData = this.filteredData()
        this.totalUsers = filterData.length
        this.bindUsers = paginateArray(filterData, this.perPage, this.currentPage)
      } else {
        this.totalUsers = this.allUsers.length
        this.bindUsers = paginateArray(this.allUsers, this.perPage, this.currentPage)
      }
    },
    filteredData() {
      const queryLowered = this.searchQuery.toLowerCase()
      return this.allUsers.filter(user => {
        const matchesName = user.firstName.toLowerCase().includes(queryLowered) || user.lastName.toLowerCase().includes(queryLowered)
        const matchesCompany = this.companyFilter ? user.companyId === this.companyFilter.id : true
        return (this.searchQuery === '' || matchesName) && matchesCompany
      })
    },
  },
  setup() {
    const tableColumns = [
      { key: 'Name', sortable: false },
      { key: 'Role', sortable: false },
      { key: 'LastLogin', sortable: false },
      { key: 'Actions', sortable: false },
    ]

    const { PER_PAGE_OPTIONS, PER_PAGE } = constants
    const refUserListTable = ref(null)

    const perPage = ref(PER_PAGE)
    const totalUsers = ref(0)
    const currentPage = ref(1)
    const perPageOptions = PER_PAGE_OPTIONS
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
      const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalUsers.value,
      }
    })

    const refetchData = () => {
      refUserListTable.value.refresh()
    }

    const {
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
    } = mixinAlert()

    return {
      tableColumns,
      perPage,
      currentPage,
      sortBy,
      isSortDirDesc,
      totalUsers,
      dataMeta,
      perPageOptions,
      refUserListTable,
      refetchData,
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
      avatarText,
      mapPackageToVariant,
      mapUserRoleToVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
